import axios from "axios";

export default async function userAuth(password) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/login`, {
        email: `${process.env.VUE_APP_AUTH_EMAIL}`,
        password: password
      });
  
      if (response.status === 200 && response.data.success === true) {
        localStorage.setItem("token", JSON.stringify(response.data.token));
        return "authorized";
      } 
    } catch (error) {
        return error;
    }
  }