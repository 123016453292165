<template>
  <div class="inset-0 flex items-center justify-center z-50">
    <div
      class="bg-white p-10 rounded-3xl border-2 border-slate-300 w-full max-w-2xl"
    >
      <div class="sm:w-full md:w-1/1 mx-auto">
        <h1 class="text-3xl font-bold mb-8 max-sm:ml-0 max-sm:text-left">
          Generate Health insurance Quote for everyone
        </h1>
      </div>
      <div class="sm:w-3/4 mx-auto text-left sm:text-center max-sm:ml-0">
        <p class="text-lg mb-10">
          For the tag <b>{{ props.bulkResponse.tag }}</b> we have found <b>{{ props.bulkResponse.count }} contacts</b>. Do you
          want to proceed?
        </p>
      </div>
      <div class="sm:w-3/4 mx-auto mt-4">
        <label
          for="contactName"
          class="block mb-2 text-sm font-medium text-red-700 text-left"
          >You have to type CONFIRM to send</label
        >
        <input
          type="text"
          v-model="confirmField"
          name="contactName"
          id="contactName"
          placeholder="Type CONFIRM"
          class="mt-1 block w-full py-2 px-4 border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
      <div class="mt-10 flex flex-col mb-3 justify-center items-center">
        <button
          @click="sendBulkRequest"
          :disabled="sendButton"
          class="enabled:bg-green-700 enabled:cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap w-full sm:w-4/5 mb-3 disabled:opacity-40 disabled:bg-gray-900"
        >
          Yes, send quotes for everyone
        </button>
        <button
          @click="props.modalState('cancel_generateQuoteForAll')"
          class="bg-red-900 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap w-full sm:w-4/5"
        >
          No, cancel
        </button>
      </div>
    </div>
  </div>
</template>
   
<script setup>
import requestNotification from "@/assets/requestNotification";
import sendBulk from "@/services/sendBulk";
import { defineProps, ref, watch } from "vue";
import { useRouter } from 'vue-router'

const props = defineProps({
  modalState: Function,
  bulkResponse: Object
})

const router = useRouter()

let confirmField = ref("");
let confirmWord = "CONFIRM";

let sendButton = ref(true);

async function sendBulkRequest() {
  const bulkRequest = await sendBulk(props.bulkResponse.tag);

  if (bulkRequest.message) {
    requestNotification(bulkRequest.message);
    router.push('/bulk-status')
  }

}

watch(confirmField, () => {
  if (confirmField.value === confirmWord) {
    sendButton.value = false;
  } else {
    sendButton.value = true;
  }

});
</script>

   
   