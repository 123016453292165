import authConfig from "@/assets/bearerConfig";
import errorMessage from "@/assets/errorPopUp";
import axios from "axios";

export default async function getCounters() {
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/quote-status`, authConfig());
        return response;
    } catch (error) {
        if (error.response && error.response.status === 500) {
            errorMessage(error.response.data.message, 500);
            throw error;
        }
        return error;
    }

}