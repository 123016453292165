import authConfig from '@/assets/bearerConfig';
import axios from "axios";


// Função para gerar pdf
export default async function sendIndivHealthQuot(data) {
  try {

    const mainContact = data[0];

    await dataManagement(mainContact);

    if (data.length > 1) {
      await dependentsDataManagement(data.slice(1))
    }

    const dependents = await processDependents(data);

    const formatedData = {
      "nb_people_household": mainContact.household,
      "income_amount": mainContact.income > 0 ? mainContact.income : 1000000,
      "income_opportunity_amount": mainContact.income_opportunity,
      "year": 2024,
      "nb_coverage": mainContact.coverage,
      "contact": {
        "user_id": mainContact.id,
        "email": mainContact.email,
        "status": mainContact.ACA_COVER,
        "state": mainContact.state,
        "zipcode": mainContact.zipcode,
        "countyfips": '12' + String(mainContact.countyfips).padStart(3, '0'),
        "full_name": mainContact.full_name,
        "age": mainContact.age,
        "dob": mainContact.dob,
        "usesTobacco": typeof mainContact.usesTobacco !== 'undefined' && mainContact.usesTobacco === 'Yes' ? true : false,
        "hasMarriedCouple": mainContact.hasMarriedCouple !== null ? true : false,
        "gender": 'Male',
        "mobile": mainContact.mobile,
	"plan": mainContact.plan,
        "plan_1": mainContact.plan_1,
        "plan_code": mainContact.plan_code,
        "plan_1_code": mainContact.plan_1_code,
        "premium": mainContact.premium,
        "premium_1": mainContact.premium_1,
      },
      "people": dependents,
      "type": "individual",
      "tag": null
    }

    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/quotes`, formatedData, authConfig());
    console.log(response);
    return response;

  } catch (error) {
    return error;
  }
}


async function dataManagement(data) {

  const requiredProps = [
    'household',
    'coverage',
    'id',
    'state',
    'zipcode',
    'countyfips',
    'full_name',
    'age',
    'dob',
    'ACA_COVER',
  ];


  for (let prop of requiredProps) {
    if (data[prop] === null || data[prop] === 'None' || data[prop] === undefined) {
      throw new Error(`The propriety '${prop}' cant be null.`);
    }
  }

  if (data['Income'] === 0) {
    throw new Error(`No plans found with that Income value`);
  }


}


async function dependentsDataManagement(data) {
  data.map((item) => {
    if (item.status === null || item.status === 'None') {
      throw new Error(`ACA COVER can't be empty`);
    }
  })
}



async function processDependents(contacts) {

  const dependents = contacts.slice(1).map((item) => {
    return {
      age: item.age,
      uses_tobacco: item.smoker === 'Yes' ? true : false,
      gender: 'Male',
      status: item.status
    }
  })

  return dependents;
}
