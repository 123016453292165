import authConfig from "@/assets/bearerConfig";
import errorMessage from "@/assets/errorPopUp";
import axios from "axios";

export default async function sendMail(quoteDetails) {

    try {
        const mainPayload = quoteDetails.data.quote;
    
        const payloadFormatted = {
            'fullname': mainPayload.payload.contact.full_name,
            'filename': mainPayload.file.filename,
            'url': mainPayload.file.url,
            'email': mainPayload.payload.contact.email,
            'mobile': mainPayload.payload.contact.mobile,
            'quote_uuid': mainPayload.uuid,
            'tax_credit_amount': mainPayload.tax_credit_amount,
            'income_amount': mainPayload.income_amount
        }
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/send-mail`, payloadFormatted, authConfig());
    
        return response;
    } catch(error) {
        console.log(error.response.data.message);
        errorMessage(error.response.data.message)
    }
}