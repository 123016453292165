import Swal from 'sweetalert2';

export default function successMessage() {
    return Swal.fire({
        title: 'Sucess!',
        text: 'You have successfully sent!',
        icon: 'success',
        confirmButtonText: 'Close'
    });

}