import { createApp } from 'vue'
import App from './App.vue'
import './index.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import router from './routes/router';




const app = createApp(App)

app.use(VueSweetalert2).use(router);

app.mount('#app');