<template>
  <div
    v-if="generateInsuranceMainScreen"
    class="inset-0 flex items-center justify-center z-50 mt-20"
  >
    <div
      class="bg-white p-6 md:p-10 sm:border border-slate-300 rounded-lg w-full max-w-2xl"
    >
      <h1 class="text-3xl font-semibold sm:mb-1 mb-3 mt-10 max-sm:text-left">
        Health Insurance Marketplace
      </h1>
      <h2 class="text-2xl font-grey mb-12 max-sm:text-left">
        Automated quote process
      </h2>
      <div class="flex flex-col md:flex-row mb-3 justify-between items-center">
        <input
          v-model="zohoID"
          @input="verifyNumber"
          class="border border-gray-300 p-3 text-lg w-full md:w-3/5 rounded focus:outline-none focus:border-blue-500 mb-4 md:mb-0 md:mr-2"
          type="text"
          required
          placeholder="Type client's Zoho ID"
        />
        <button
          @click="getContact(zohoID, 'moveToIndivQuote')"
          class="bg-indigo-900 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap w-full md:w-2/5"
        >
          Generate Individual Quote
        </button>
      </div>
      <p class="text-center font-semibold text-lg mb-3">or</p>
      <div class="flex flex-col md:flex-row justify-between items-center mb-10">
        <input
          v-model="zohoTag"
          class="border border-gray-300 p-3 text-lg w-full md:w-3/5 rounded focus:outline-none focus:border-blue-500 mb-4 md:mb-0 md:mr-2"
          type="text"
          placeholder="Type Zoho Tag"
        />
        <button
          @click="bulkContact(zohoTagFormatted, 'moveToGenerateForAll')"
          class="bg-indigo-900 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap w-full md:w-2/5"
        >
          Generate quote for all
        </button>
      </div>
      <button
          @click="router.push('/bulk-status')"
          class="bg-indigo-900 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap w-full md:w-2/5"
        >
          Check Bulk Status
        </button>
    </div>
  </div>

  
  <!-- Confirm Generate Individual Quote PopUp -->
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    v-if="confirmGenerateIndividualQuote"
  >
    <ConfirmGenerateQuotePopUp
      :modalState="ToggleNextPage"
      :confirmGenerate="generateQuoteJobs"
      :contactResponse="contactResponse"
    />
  </div>

  <!-- Generate and send quote for a person -->
  <div v-if="sendQuotePage">
    <SendIndividualQuote
      :modalState="ToggleNextPage"
      :quoteResponse="quoteResponse"
    />
  </div>

  <!-- Generate Quote for everyone PopUp -->
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    v-if="generateQuoteForAll"
  >
    <GenerateForEveryonePopUp :modalState="ToggleNextPage" :bulkResponse="bulkQuantityResponse"/>
  </div>
</template>

<script setup>
// Imports

import { ref, defineProps, computed } from "vue";

// Components

import ConfirmGenerateQuotePopUp from "./ConfirmGenerateQuotePopUp.vue";
import GenerateForEveryonePopUp from "../Generate for Everyone/GenerateForEveryone.vue";
import SendIndividualQuote from "../Generate Individual Quote/GenerateIndividualQuoteForm.vue";
import requestContact from "@/services/getZohoContact";
import bulkRequest from "@/services/bulkService";


//PopUp
import errorMessage from "@/assets/errorPopUp";
import successMessage from "@/assets/successPopUp";
import sendIndivHealthQuot from "@/services/sendQuoteRequest";
import warningPopUp from "@/assets/warningPopUp";
import { useRouter } from 'vue-router'

const props = defineProps({
  logout: Function,
});

const router = useRouter();

// Page Variables
let generateInsuranceMainScreen = ref(true);
let confirmGenerateIndividualQuote = ref(false);
let generateQuoteForAll = ref(false);
let sendQuotePage = ref(false);

let contactResponse;
let fullDependentsResponse;
let quoteResponse;
let bulkQuantityResponse;

// Input holders
let zohoID = ref("");
let zohoTag = ref("");

let zohoTagFormatted = computed(() => {
  return zohoTag.value.replace(/\s/g, "%20");
});

function logout() {
  generateInsuranceMainScreen.value = false;
  props.logout();
  errorMessage("Invalid Authorization", "You need to authenticate again");
}

function verifyNumber() {
  if (isNaN(zohoID.value)) {
    zohoID.value = zohoID.value.replace(/[^0-9]/g, "");
  }
}

function resetFields() {
  zohoID.value = '';
  zohoTag.value = '';
}

function ToggleNextPage(popup) {
  if (popup === "close_generateIndividualQuote") {
    confirmGenerateIndividualQuote.value = false;
  } else if (popup === "open_generateIndividualQuote") {
    generateInsuranceMainScreen.value = false;
    confirmGenerateIndividualQuote.value = false;
    sendQuotePage.value = true;
  } else if (popup === "cancel__quote") {
    generateInsuranceMainScreen.value = true;
    sendQuotePage.value = false;
  } else if (popup === "send__quote") {
    successMessage();
  } else if (popup === "cancel_generateQuoteForAll") {
    generateQuoteForAll.value = false;
  }

  resetFields()
}


async function getContact(authData, moveNextPage, endpoint) {
  if (zohoID.value.length > 0) {
    const auth = await requestContact(parseInt(authData), endpoint);

    if (auth.status === 200) {
      if (auth.data.contact && auth.data.contact.length > 0) {

        if (moveNextPage === "moveToIndivQuote") {
          confirmGenerateIndividualQuote.value = true;
          contactResponse = auth.data.contact[0];
          fullDependentsResponse = auth.data.contact;
        }
      } else {
        warningPopUp("Contact not found");
      }
    } else if (auth.response.status === 401) {
      logout();
      localStorage.removeItem("token");
    }
  } else {
    warningPopUp("Please enter your Zoho ID.");
  }
}

async function generateQuoteJobs() {
  const response = await sendIndivHealthQuot(fullDependentsResponse);

  quoteResponse = response;

  return response;
}


async function bulkContact(zohoTag) {
  const contactsFound = await bulkRequest(zohoTag);

  if (contactsFound.count > 0) {
    generateQuoteForAll.value = true;
    bulkQuantityResponse = contactsFound;
  }

  if (contactsFound.count === 0) {
    errorMessage('No contacts were found with the given tag.')
  }

}
</script>

