<template>
    <div class="inset-0 flex items-center justify-center z-50 mt-20">
        <div class="bg-white p-10 rounded-3xl sm:border-2 border-slate-300 w-full max-w-2xl">
            <div class="w-3/4 mx-auto max-sm:ml-0 max-sm:w-full">
                <h1 class="max-[320px]:text-2xl max-[639px]:text-3xl max-sm:text-left sm:text-3xl font-bold mb-8">Send Individual Health Insurance Quote</h1>
            </div>
            <div id="quote" class="sm:w-3/4 mx-auto mb-8 max-sm:ml-0 sm:text-center text-left">
                <p class="text-lg">Your quote is ready <a :href="props.quoteResponse.data.quote.file.url" target="_blank"><b>(preview PDF file)</b></a> Please confirm the email submission with the attachment.</p>
            </div>
            
            <div class="mt-10 flex flex-col sm:flex-row mb-3 justify-around items-center">
                <button
                    @click="sendEmail"
                    class="bg-green-700 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap max-sm:w-full sm:w-2/5 max-sm:mb-3">Send
                    to customer</button>
                <button
                    @click="props.modalState('cancel__quote')"
                    class="bg-red-900 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap max-sm:w-full sm:w-2/5 max-sm:mb-3">No,
                    cancel</button> 
            </div>
        </div>
    </div>
</template>
    
<script setup>
import { defineProps } from "vue"
import sendMail from "@/services/sendMail";
import errorMessage from "@/assets/errorPopUp";
import sucessEmailMessage from "@/assets/sucessPopUpMail";


const props = defineProps({
  modalState: Function,
  quoteResponse: Object
})

//TODO: tratar casos de erro com mensagem customizadas. Backend vai retornar payload nesse formato
/* '                success' => false,
                    'message' => 'The quote was not sent',
                    'error' => [
                        'email' => Array,
                        'whatsapp' => Array
                    ] */

async function sendEmail() {
    const mailResponse = await sendMail(props.quoteResponse);

    if (mailResponse) {
    if (mailResponse.data.success === true) {
        sucessEmailMessage(mailResponse.data.message);
        setTimeout(() => {
            props.modalState('cancel__quote')
        }, 500)
    } else {
        errorMessage(mailResponse);
    }
}
}



</script>

    