<template>
  <div class="poppins">
    <div class="flex justify-between mt-4 mb-5">
      <button
        @click="router.push('/')"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-5"
      >
        Voltar
      </button>
      <button
        @click="reloadPage()"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-5"
      >
        Atualizar dados
      </button>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-base font-large text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-base font-large text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-base font-large text-gray-500 uppercase tracking-wider"
                  >
                    Total Quotes
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-base font-large text-gray-500 uppercase tracking-wider"
                  >
                    Logs
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-base font-large text-gray-500 uppercase tracking-wider"
                  >
                    Created at
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white divide-y divide-gray-200">
                <template v-for="(item, index) in bulkStatus" :key="index">
                  <tr>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-base text-left text-gray-900 font-bold">
                        {{ item.id }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div
                        class="text-base text-left text-gray-900 font-bold"
                        :class="item.status === 'finished' ? 'text-green-700' : 'text-orange-400'"
                      >
                        {{ item.status }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap font-bold">
                      <div class="text-base text-left text-gray-900">
                        {{ item.total_quotes }}
                      </div>
                    </td>
                    <td @click="filterCounters(item.id), findSelectedRow()" class="px-6 py-4 whitespace-nowrap font-bold flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-5 align-middle text-purple-400 mr-1 cursor-pointer"
                        style="vertical-align: middle;"

                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      <div
                        class="text-base text-left text-gray-900 cursor-pointer poppins"
                      >
                        Details
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap font-bold">
                      <div class="text-base text-left text-gray-900">
                        {{ new Date(item.created_at).toLocaleString("en-US") }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="selectedRow === item.id">
                    <td colspan="5">
                      <div class="flex justify-around bg-gray-50">
                        <div
                          class="text-left flex flex-col space-y-2 p-4 bg-gray-50 rounded-lg"
                          v-for="(detail, index) in bulkDetails"
                          :key="index"
                        >
                          <div class="flex">
                            <svg
                              v-if="detail.counter_type === 'email'"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-7 h-7 text-purple-400"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                              />
                            </svg>

                            <svg
                              v-if="detail.counter_type === 'quote'"
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-7 h-6"
                              enable-background="new 0 0 64 64"
                              viewBox="0 0 64 64"
                              id="pdf-file"
                            >
                              <path
                                fill="#eff2f3"
                                d="M58.2,3.2l0,49H5.8l0-38.4L19.6,0l35.5,0C56.8,0,58.2,1.5,58.2,3.2z"
                              ></path>
                              <path
                                fill="#dadede"
                                d="M16.7,13.8l-10.8,0L19.6,0l-0.1,10.8C19.6,12.5,18.3,13.8,16.7,13.8z"
                              ></path>
                              <path
                                fill="#f2786b"
                                d="M37.1 28c-2.9-2.8-5-6-5-6 .8-1.2 2.7-8.1-.2-10.1-2.9-2-4.4 1.7-4.4 1.7-1.3 4.6 1.8 8.8 1.8 8.8l-3.5 7.7c-.4 0-11.5 4.3-7.7 9.6 3.9 5.3 9.3-7.5 9.3-7.5 2.1-.7 8.5-1.6 8.5-1.6 2.5 3.4 5.5 4.5 5.5 4.5 4.6 1.2 5.1-2.6 5.1-2.6C46.6 27.5 37.1 28 37.1 28zM20 37.9c-.1 0-.1-.1-.1-.1-.6-1.4 4-4.1 4-4.1S21.4 38.5 20 37.9zM30.4 13.7c1.3 1.2.2 5.3.2 5.3S29.1 14.9 30.4 13.7zM29.2 29.2l1.8-4.4 2.8 3.4L29.2 29.2zM44 32.4C44 32.4 44 32.4 44 32.4L44 32.4 44 32.4c-.8 1.3-4.1-1.5-4.4-1.8l0 0c0 0 0 0 0 0 0 0 0 0 0 0l0 0C40.1 30.6 44.4 30.9 44 32.4L44 32.4zM58.2 49l0 11.8c0 1.7-1.4 3.2-3.2 3.2L8.9 64c-1.7 0-3.2-1.4-3.2-3.2l0-11.8H58.2z"
                              ></path>
                              <path
                                fill="#eff2f3"
                                d="M27.9 54.2c0 .8-.3 1.4-.8 1.9-.5.4-1.3.6-2.3.6h-.8v2.9h-1.3v-7.7H25c1 0 1.7.2 2.2.6C27.7 52.9 27.9 53.4 27.9 54.2zM24.1 55.7h.7c.6 0 1.1-.1 1.4-.3.3-.2.5-.6.5-1.1 0-.4-.1-.8-.4-1-.3-.2-.7-.3-1.3-.3h-.9V55.7zM35.8 55.7c0 1.3-.4 2.3-1.1 2.9-.7.7-1.7 1-3.1 1h-2.2v-7.7h2.4c1.2 0 2.2.3 2.9 1C35.4 53.5 35.8 54.5 35.8 55.7zM34.4 55.7c0-1.9-.9-2.8-2.6-2.8h-1.1v5.6h.9C33.5 58.6 34.4 57.6 34.4 55.7zM38.7 59.6h-1.3v-7.7h4.4v1.1h-3.1v2.4h2.9v1.1h-2.9V59.6z"
                              ></path>
                            </svg>

                            <svg
                              v-if="detail.counter_type === 'whatsapp'"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              class="w-7 h-6"
                              viewBox="0 0 1024 1024"
                              id="whatsapp"
                            >
                              <defs>
                                <path
                                  id="b"
                                  d="M1023.941 765.153c0 5.606-.171 17.766-.508 27.159-.824 22.982-2.646 52.639-5.401 66.151-4.141 20.306-10.392 39.472-18.542 55.425-9.643 18.871-21.943 35.775-36.559 50.364-14.584 14.56-31.472 26.812-50.315 36.416-16.036 8.172-35.322 14.426-55.744 18.549-13.378 2.701-42.812 4.488-65.648 5.3-9.402.336-21.564.505-27.15.505l-504.226-.081c-5.607 0-17.765-.172-27.158-.509-22.983-.824-52.639-2.646-66.152-5.4-20.306-4.142-39.473-10.392-55.425-18.542-18.872-9.644-35.775-21.944-50.364-36.56-14.56-14.584-26.812-31.471-36.415-50.314-8.174-16.037-14.428-35.323-18.551-55.744-2.7-13.378-4.487-42.812-5.3-65.649-.334-9.401-.503-21.563-.503-27.148l.08-504.228c0-5.607.171-17.766.508-27.159.825-22.983 2.646-52.639 5.401-66.151 4.141-20.306 10.391-39.473 18.542-55.426C34.154 93.24 46.455 76.336 61.07 61.747c14.584-14.559 31.472-26.812 50.315-36.416 16.037-8.172 35.324-14.426 55.745-18.549 13.377-2.701 42.812-4.488 65.648-5.3 9.402-.335 21.565-.504 27.149-.504l504.227.081c5.608 0 17.766.171 27.159.508 22.983.825 52.638 2.646 66.152 5.401 20.305 4.141 39.472 10.391 55.425 18.542 18.871 9.643 35.774 21.944 50.363 36.559 14.559 14.584 26.812 31.471 36.415 50.315 8.174 16.037 14.428 35.323 18.551 55.744 2.7 13.378 4.486 42.812 5.3 65.649.335 9.402.504 21.564.504 27.15l-.082 504.226z"
                                ></path>
                              </defs>
                              <linearGradient
                                id="a"
                                x1="512.001"
                                x2="512.001"
                                y1=".978"
                                y2="1025.023"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#61fd7d"></stop>
                                <stop offset="1" stop-color="#2bb826"></stop>
                              </linearGradient>
                              <use
                                fill="url(#a)"
                                overflow="visible"
                                xlink:href="#b"
                              ></use>
                              <path
                                fill="#FFF"
                                d="M783.302 243.246c-69.329-69.387-161.529-107.619-259.763-107.658-202.402 0-367.133 164.668-367.214 367.072-.026 64.699 16.883 127.854 49.017 183.522l-52.096 190.229 194.665-51.047c53.636 29.244 114.022 44.656 175.482 44.682h.151c202.382 0 367.128-164.688 367.21-367.094.039-98.087-38.121-190.319-107.452-259.706zM523.544 808.047h-.125c-54.767-.021-108.483-14.729-155.344-42.529l-11.146-6.612-115.517 30.293 30.834-112.592-7.259-11.544c-30.552-48.579-46.688-104.729-46.664-162.379.066-168.229 136.985-305.096 305.339-305.096 81.521.031 158.154 31.811 215.779 89.482s89.342 134.332 89.312 215.859c-.066 168.243-136.984 305.118-305.209 305.118zm167.415-228.515c-9.177-4.591-54.286-26.782-62.697-29.843-8.41-3.062-14.526-4.592-20.645 4.592-6.115 9.182-23.699 29.843-29.053 35.964-5.352 6.122-10.704 6.888-19.879 2.296-9.176-4.591-38.74-14.277-73.786-45.526-27.275-24.319-45.691-54.359-51.043-63.543-5.352-9.183-.569-14.146 4.024-18.72 4.127-4.109 9.175-10.713 13.763-16.069 4.587-5.355 6.117-9.183 9.175-15.304 3.059-6.122 1.529-11.479-.765-16.07-2.293-4.591-20.644-49.739-28.29-68.104-7.447-17.886-15.013-15.466-20.645-15.747-5.346-.266-11.469-.322-17.585-.322s-16.057 2.295-24.467 11.478-32.113 31.374-32.113 76.521c0 45.147 32.877 88.764 37.465 94.885 4.588 6.122 64.699 98.771 156.741 138.502 21.892 9.45 38.982 15.094 52.308 19.322 21.98 6.979 41.982 5.995 57.793 3.634 17.628-2.633 54.284-22.189 61.932-43.615 7.646-21.427 7.646-39.791 5.352-43.617-2.294-3.826-8.41-6.122-17.585-10.714z"
                              ></path>
                            </svg>

                            
                            <a
                            :href="detail.error_file ? detail.error_file : '#'"
                              :target="detail.error_file ? '_blank' : ''"
                              class="text-lg font-bold text-gray-900 ml-1 overflow-hidden border-b border-gray-200"
                              :class="detail.error_file ? 'hover:bg-red-700' : 'hover:bg-green-600 cursor-default'"

                            >
                              <span class="font-bold text-stone-800">{{
                                detail.counter_type.charAt(0).toUpperCase() +
                                detail.counter_type.slice(1) +
                                " Logs"
                              }}</span>
                            </a>
                          </div>
                          <div class="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-7 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                              />
                            </svg>

                            <h2 class="text-md text-gray-700 font-bold">
                              Total Quotes:
                              <span class="font-bold">{{
                                detail.counter_type === "email" || detail.counter_type === "whatsapp"
                                  ? verifyField(
                                      detail.pending,
                                      detail.errors,
                                      detail.success
                                    )
                                  : detail.total
                              }}</span>
                            </h2>
                          </div>
                          <div class="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-7 h-6 text-yellow-500"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                              />
                            </svg>

                            <h2 class="text-md text-yellow-900 font-bold">
                              Pending:
                              <span class="font-bold">{{
                                detail.pending
                              }}</span>
                            </h2>
                          </div>
                          <div class="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-7 h-6 text-green-800"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                            <h2 class="text-md text-green-700 font-bold">
                              Successful Quotes:
                              <span class="font-bold">{{
                                detail.success
                              }}</span>
                            </h2>
                          </div>
                          <div class="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-7 h-6 text-red-800"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>

                            <h2 class="text-md text-red-700 font-bold">
                              Errors:
                              <span class="font-bold">{{
                                detail.errors
                              }}</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
     
<script setup>
import { onMounted, ref, toRaw, watch } from "vue";
import { useRouter } from "vue-router";
import getCounters from "../../services/bulkStatus";

const router = useRouter();
let bulkStatus = ref();
let bulkDetails = ref();
let selectedRow = ref(null);

async function fetchCounters() {
  const counter = await getCounters();
  return counter.data;
}

async function updatePage() {
  const counter = await fetchCounters();  
  bulkStatus.value = counter;
}

async function reloadPage() {
    await updatePage().then(findSelectedRow);
}

function filterCounters(id) {
  selectedRow.value = id;
  localStorage.setItem("row", JSON.stringify(id));
}

function findSelectedRow() {
    let row = localStorage.getItem('row');

    const bulkStatusItem = bulkStatus.value.find((item) => item.id === parseInt(row));

    if (bulkStatusItem) {
         bulkDetails.value = toRaw(bulkStatusItem.counters);
    }
}

function verifyField(pending, errors, sucess) {
  const totalMails = pending + errors + sucess;

  return totalMails;
}
let thi = 0
function infiniteCallBack() {
    setTimeout(() => {
        reloadPage();
        infiniteCallBack();
        thi++;
        console.log("Montei", thi)
    }, 15000)
}

onMounted(() => {   
    updatePage().then(infiniteCallBack)
});



</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

.poppins {
  font-family: "Poppins", sans-serif;
}
</style>
  
     
     