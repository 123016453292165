import authConfig from "@/assets/bearerConfig";
import errorMessage from "@/assets/errorPopUp";
import axios from "axios";

export default async function sendBulk(zohoTag) {
    try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/tag/quotes`, 
        { 
            tag: zohoTag 
        }, 
        authConfig());
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 500) {
            errorMessage(error.response.data.message, 500);
            throw error;
        }
        return error;
    }
}