import Swal from 'sweetalert2';

export default function warningPopUp(errorTitle, errorMessage) {
    return Swal.fire({
        title: errorTitle,
        text: errorMessage,
        icon: 'warning',
        confirmButtonText: 'Close'
    });

}