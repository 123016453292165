<template>
    <div v-if="!userAuthenticated"
    class="inset-0 flex items-center justify-center z-50"
  >
    <div
      class="bg-white p-6 md:p-10 sm:border border-slate-300 rounded-lg w-full max-w-2xl"
    >
      <h1 class="text-3xl font-semibold sm:mb-1 mb-3 mt-10 max-sm:text-left">
        Health Insurance Marketplace
      </h1>
      <h2 class="text-2xl font-grey mb-12 max-sm:text-left">
        Automated quote process
      </h2>
      <div class="flex flex-col md:flex-row mb-3 justify-between items-center">
        <input
          class="border border-gray-300 p-3 text-lg w-full md:w-3/5 rounded focus:outline-none focus:border-blue-500 mb-4 md:mb-0 md:mr-2"
          type="password"
          placeholder="Type your password"
          v-model="password"
        />
        <button
          class="bg-indigo-900 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap w-full md:w-2/5"
          @click="sendUserPassword"
        >
        Proceed
        </button>
      </div>
    </div>
  </div>

<div v-if="userAuthenticated">
  <InsurancePage :logout="logout"/>
</div>

</template>


<script setup>

import InsurancePage from '../Main Screen/GenerateInsuranceMainScreen.vue';
import userAuth from '@/services/userAuth';
import { ref, onMounted } from "vue";
import errorMessage from '@/assets/errorPopUp';


let userAuthenticated = ref(false);

let password = ref('');

function logout() {
  userAuthenticated.value = false;
}



onMounted(async() => {
  let token = localStorage.getItem('token');

  if (token) {
    userAuthenticated.value = true;
  }
 
});

async function sendUserPassword() {
  try {
    const result = await userAuth(password.value);
    if (result === 'authorized') {
      userAuthenticated.value = true;
    } else {
      errorMessage(result.response.data.error, result.response.data.message)
    }
  } catch (error) {
    console.error(error);
  }
}



</script>