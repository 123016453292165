import Swal from 'sweetalert2';

export default function requestNotification(message) {
    Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 2000
      })
}