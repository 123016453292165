import Swal from 'sweetalert2';

export default function sucessEmailMessage(message) {
    return Swal.fire({
        title: 'Sucess!',
        text: message,
        icon: 'success',
        confirmButtonText: 'Close'
    });

}