import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Main Screen/LoginScreen.vue'
import BulkPage from '../components/Bulk Status Page/BulkPage.vue'


const routes = [
  { path: '/', component: Login },
  { path: '/bulk-status', component: BulkPage }
]

const router = createRouter({
  history: createWebHistory(),
  routes, 
})

export default router