<template>
  <div class="inset-0 flex items-center justify-center z-50">
    <div
      class="bg-white p-10 rounded-3xl border-2 border-slate-300 w-full max-w-2xl"
    >
      <div class="sm:w-full mx-auto">
        <h1
          class="max-[320px]:text-2xl text-3xl sm:w-10/12 mx-auto font-bold mb-8 max-sm:text-left"
        >
          Generate Individual Health Insurance Quote
        </h1>
      </div>

            <div class="w-4/4 sm:mx-auto sm:text-center text-left">
                <p class="text-lg">Do you confirm that you would like to generate for customer <b>{{ contactResponse.full_name }}</b> with
                    DOB {{ contactResponse.dob }}?</p>
            </div>
            <div class="mt-10 flex flex-col sm:flex-row mb-3 justify-around items-center">
                <button
                    @click="awaitQuoteResponse"
                    class="bg-green-700 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap w-full sm:w-2/5 max-sm:mb-3">Yes,
                    confirm</button>
                <button
                    @click="props.modalState('close_generateIndividualQuote')"
                    class="bg-red-900 cursor-pointer text-white p-3 rounded text-lg whitespace-nowrap w-full sm:w-2/5 max-sm:mb-3">No,
                    cancel</button>
            </div>
        </div>
    </div>
</template>
   
<script setup>
import { defineProps } from "vue";
import errorMessage from "@/assets/errorPopUp";

const props = defineProps({
  modalState: Function,
  confirmGenerate: Function,
  contactResponse: Object,
});

async function awaitQuoteResponse() {
  const response = await props.confirmGenerate();
  let errorMsg = '';

  if(response && response.response && response.response.data && response.response.data.message) {
    errorMsg = response.response.data.message;
} else {
    errorMsg = response;
}


  if (response.status === 200) {
    props.modalState("open_generateIndividualQuote");
  } else {
    errorMessage(errorMsg);
  }
}
</script>
   